export const DL_PROJECT_DOC = 'Documents projet';
export const DL_TRANSMISSION_SHEET = 'Fiche de transmission';
export const DL_DOSSIER_CLIENT = 'Dossier client';
export const DL_PIECE_JUSTIF = 'Pièces justificatives';
export const DL_DOE = 'Dernière offre éditée ';
export const DL_DPP = 'Dernière DDP éditée ';

export const COMMON_PDF = {
  file: 'Dossier :',
};

export const TRANSMI_SHEET = {
  dlName: 'FICHETRANSMISSION_',
  mainTitle: 'FICHE DE TRANSMISSION',
  editedDate: 'Editée le',
  provider: 'Apporteur :',
  editor: 'Monteur',
  seller: 'Vendeur',
  analyst: 'Analyste EP',
  caracteristics: 'Caractéristiques :',
  fileType: 'RC',
  rateType: 'Fixe',
  warranty: 'GARANTIE PAR UNE HYPOTHEQUE',
  wo_warranty: 'SANS GARANTIE',
  legal_mention_1: 'BNP Paribas Personal Finance - S.A au capital de 561 882 202 euros',
  legal_mention_2:
    'Siège social : 1, Boulevard Haussman 75318 Paris cedex 09 - N° 542 097 902 RCS Paris',
  legal_mention_3: 'N° ORIAS 07 023 128 - N° ADEME FR200182_03KLJL',
};

export const CLIENT_FILE = {
  info: 'info client',
  borrower: 'Emprunteur',
  coBorrower: 'CoEmprunteur',
  dlName: 'DOSSIERCLIENT_',
  mainTitle: 'DOSSIER CLIENT',
  dossier_number: 'Numéro de dossier',
  segment: 'Segmentation',
  projet: 'Type de projet',
  processTraitement: 'Procces traitement',
  coutier_number: 'N° courtier',
  Name: 'Nom',
  zip_code: 'Code postal',
  city: 'Ville',
  country: 'Pays',
  miob: 'Nom du Miob',
  monteur: 'monteur',
  vendeur: 'vendeur',
  analyste: 'Analyste EP',
  identity: {
    title: 'Civilité',
    prenom: 'Prénom',
    birth_name: 'Nom de naissance',
    birth_date: 'Date de naissance',
    birth_dept: 'Dépt de naissance',
    birt_city: 'Ville de naissance',
    nationality: 'Nationalité',
    birth_country: 'Pays de naissance',
    sit_famille: 'Situation familiale',
    identity_paper: `Pièce d'indentité`,
    paper_number: `Numéro de la Pièce`,
  },
  adresse: {
    title: 'ADRESSE DOMICILE',
    cplt: 'Cplt. adresse',
    street: 'Rue',
    post_box: 'Boite postale ou lieu-dit',
    mail: 'Adresse mail',
    fix_phone: 'Tél. domicile',
    mobile_phone: 'Tél. portable',
    habitat: 'Résidence principale',
    logement: 'Logement',
    entry_date: 'Année entrée logement',
  },
  famile: {
    title: 'Situation familiale',
    number_foyer: 'Nombre de personnes dans le foyer',
    Number_child: `Nombre d'enfants à charge`,
    birthdate_child: `Années de naissance des enfants à charge`,
  },
  profession: {
    title: 'RENSEIGNEMENTS PROFESSIONNELS EMPRUNTEUR(S)',
    contrat: 'Contrat de travail',
    category: 'Catégorie socio-pro',
    employeur: 'Employeur',
    zip_code: 'Code postal employeur',
    city: 'Ville employeur',
    country: 'Pays employeur',
    job_date: 'Depuis le',
    SIREN_APE: 'N° SIREN / APE',
  },
  revenue: {
    title: 'REVENUS MENSUELS',
    salaire_emp: 'Salaire net emprunteur',
    salaire_coemp: 'Salaire net Co-emprunteur',
    type_revenue: 'Type de ressource',
    value: 'Montant',
    retraite: 'Retraites',
    total_revenue: 'Total des revenus',
    charge_title: 'CHARGES MENSUELLES',
    type_charge: 'Type de charge',
    total_charge: 'Total des charges mensuelles',
    banque_title: 'RENSEIGNEMENTS BANQUAIRES',
    banque: 'Banque',
    annee_ouverture: `Ouvert depuis`,
  },
  pret: {
    title: 'PRETS',
    numero: 'N°',
    crediteur: 'Société de crédit',
    solde: 'Solde restant dû',
    IRA: ['Indemnités', 'de RA'],
    mensualite: 'Mensualité',
    type_pret: 'Nature du prêt',
    date_debut: 'Date de début du crédit',
    taux: `Taux d'intérêt nominal`,
    preavis: 'Préavis',
    buyback: 'A Racheter',
    capital: 'Capital initial',
    restructuration: 'Restructuré',
    duree: 'Durée actualisée',
    morgage: 'Inscrit',
    nature: 'Usage / Nature bien',
  },
  dette: {
    title: 'DETTES',
    nature: 'Nature de dette',
    valeur: 'Montant',
    creancier: 'Identité du créancier',
    date: `Date d'exigibilité`,
    banque: 'Banque :',
    none: 'Aucune dette à racheter',
  },
  besoin: {
    title: 'BESOINS COMPLEMENTAIRES',
    type: 'Type de besoin',
    value: 'Montant',
    none: 'Aucun besoin complémentaire',
  },
  comportement: {
    title: 'COMPORTEMENT FINANCIER',
    incident: `Présence d'incidents de paiement`,
    impayer: `Présence d'impayés sur mensualité de crédits`,
    nombre_impayer: 'Nombre total de mois avec impayés',
  },
  synthèse: {
    title: 'SYNTHESE DU BESOIN',
    mensualites: 'Mensuailités',
    encours: 'Encours',
    pret: 'Prêts',
    dettes: 'Dettes',
    besoin: 'Besoins complémentaires',
    total: {
      mensualite_rachetter: 'Total mensualités rachetées',
      mensualite_non_rachetter: 'Total mensualités non rachetées',
      besoin: 'Total besoin global client',
      encours: 'Total encours non racheté',
    },
    buyback: 'Rachetés',
    keep: 'Conservée',
  },
};

export const PIECES_JUSTIF = {
  dlName: 'LISTEPIECESJUSTIFICATIVES_',
  mainTitle: 'LISTE DES PIECES JUSTIFICATIVES',
  subTitle: 'DOCUMENT A USAGE STRICTEMENT INTERNE',
  libelle: 'Libellé de pièce',
  statut: 'Statut',
};
