import { Asset, Loan, Operation, OperationState } from 'reducers/operationClient/types';
import {
  checkIfCurrentAssetDatasAreOk,
  checkIfCurrentLoanDatasAreOk,
  checkIfDatasAreOk,
} from 'reducers/operationClient/utils';
import { CodeLibelle, CodeLibelleDetail } from 'types';
import { clientFileResult, biensImmobilier, credit } from 'types/clientFileDtos';
import { zipcodeNumbertoString } from 'utils/commun';
import { ACCOMODATION_TYPE_LIST, CREDIT_TYPE_LIST } from 'utils/constante';
import { formatDateFr } from 'utils/DateUtils';

interface returnMappingLoan {
  loanList: Loan[];
  buybackLoansTotal: number;
  buybackLoansMonthlyPaymentTotal: number;
  keepLoansTotal: number;
  keepLoansMonthlyPaymentTotal: number;
}
interface returnMappingAsset {
  assetList: Asset[];
  totalAssetsValue: number;
}

interface returnMappingAutresBesoin {
  otherNeedsList: Operation[];
  buybackDebtsList: Operation[];
  totalOtherNeedsAmount: number;
  totalDebtValue: number;
  hasOtherNeeds: boolean;
  hasBuybackDebt: boolean;
}

interface returnMappingIncidentsImpayes {
  hasPaymentIncident: boolean;
  hasPaymentDefault: boolean;
  nbPaymentDefault: number;
  incidentPaymentList: CodeLibelle[];
}

const mappingLoan = (
  credits: credit[],
  listeEtablissementPreteur: CodeLibelle[],
  contexteArrivee: string,
  hasProposition: boolean,
): returnMappingLoan => {
  const loanList: Loan[] = [];
  const CreditTypeList = CREDIT_TYPE_LIST;
  let buybackLoansTotal = 0;
  let buybackLoansMonthlyPaymentTotal = 0;
  let keepLoansTotal = 0;
  let keepLoansMonthlyPaymentTotal = 0;

  credits.forEach((creditData: credit, index) => {
    const goodPreteur = listeEtablissementPreteur.find(
      item => item.code === creditData.societe,
    );
    const loanData: Loan = {
      code: index,
      buyBack: creditData.rachatIndicateur === 'O',
      creditType: CreditTypeList.get(creditData.nature),
      loaner: goodPreteur,
      ...(creditData.datePret &&
      formatDateFr(new Date(creditData.datePret)) !== '31/12/1899'
        ? { loanStartDate: formatDateFr(new Date(creditData.datePret)) }
        : {}),
      monthlyPayment: creditData.mensualite?.toString() || '',
      ...(creditData.dureeActualisee && creditData.dureeActualisee !== 0
        ? { totalLoanDuration: creditData.dureeActualisee?.toString() }
        : {}),
      balance: creditData.solde?.toString(),
      ...(creditData.montantInitialPret && creditData.montantInitialPret !== 0
        ? { initialLoanCapital: creditData.montantInitialPret?.toString() }
        : {}),
      restructure: creditData.pretRestructureIndicateur === 'O',
      mortgage: creditData.indicateurInscrit === 'O',
      isDataOk: contexteArrivee !== 'ASRC' && !hasProposition,
    };
    if (loanData.buyBack) {
      buybackLoansTotal += creditData.solde;
      buybackLoansMonthlyPaymentTotal += creditData.mensualite;
    } else {
      keepLoansTotal += creditData.solde;
      keepLoansMonthlyPaymentTotal += creditData.mensualite;
    }

    loanData.isDataOk = checkIfCurrentLoanDatasAreOk(loanData);

    loanList.push(loanData);
  });

  const result = {
    loanList,
    buybackLoansTotal,
    buybackLoansMonthlyPaymentTotal,
    keepLoansTotal,
    keepLoansMonthlyPaymentTotal,
  };

  return result;
};

const mappingAsset = (
  biensImmobiliers: biensImmobilier[],
  usageBiensList: CodeLibelle[],
  contexteArrivee: string,
  hasProposition: boolean,
): returnMappingAsset => {
  const assetList: Asset[] = [];
  const accomodationTypeList = ACCOMODATION_TYPE_LIST;
  let totalAssetsValue = 0;

  biensImmobiliers
    .filter(bienImmo => bienImmo.prisEnGarantieIndicateur !== 'N')
    .forEach((bienImmo: biensImmobilier, index) => {
      const goodUsageBien = usageBiensList.find(item => item.code === bienImmo.usage);
      const garantie: Asset = {
        code: index,
        type: accomodationTypeList.get(bienImmo.nature),
        usage: goodUsageBien,
        currentValue: bienImmo.valeurEstimee?.toString(),
        inscriptionRank: bienImmo.rangHypotheque?.toString(),
        keptMortagagesValue: bienImmo.valeurHypothequesConservees?.toString(),
        address: bienImmo.adresse.adresse,
        zipCode: zipcodeNumbertoString(bienImmo.adresse.codePostal),
        city: bienImmo.adresse.ville,
        isDataOk: contexteArrivee !== 'ASRC' && !hasProposition,
        isAssetAddressBorrowerAddress: bienImmo.adresseIdentiqueEmpIndicateur === 'O',
      };

      garantie.isDataOk = checkIfCurrentAssetDatasAreOk(garantie);

      assetList.push(garantie);
      totalAssetsValue += bienImmo.valeurEstimee;
    });

  return { assetList, totalAssetsValue };
};

const mappingAutresBesoin = (
  clientFile: clientFileResult,
  additionalLoanPurpose: CodeLibelleDetail[],
  debtTypeList: CodeLibelleDetail[],
): returnMappingAutresBesoin => {
  const otherNeedsList: Operation[] = [];
  const buybackDebtsList: Operation[] = [];
  let totalOtherNeedsAmount = 0;
  let totalDebtValue = 0;
  let hasOtherNeeds = false;
  let hasBuybackDebt = false;
  if (
    clientFile.projet?.besoinsComplementaires &&
    clientFile.projet?.besoinsComplementaires.length > 0
  ) {
    clientFile.projet?.besoinsComplementaires.forEach((besoincomplementaire, index) => {
      const goodadditionnal = additionalLoanPurpose.find(
        item => item.code === besoincomplementaire.type,
      );
      const otherNeed: Operation = {
        code: goodadditionnal?.code || '',
        uid: `${besoincomplementaire.type}-${index}`,
        libelle: goodadditionnal?.libelle || '',
        type: goodadditionnal?.ordreString || '',
        amount: besoincomplementaire.montant,
      };

      otherNeedsList.push(otherNeed);
      totalOtherNeedsAmount += besoincomplementaire.montant;
    });
    hasOtherNeeds = true;
  }

  if (clientFile.projet?.dettes && clientFile.projet?.dettes.length > 0) {
    clientFile.projet?.dettes.forEach((dette, index) => {
      const goodDept = debtTypeList.find(item => item.code === dette.type);
      const buybackDebts: Operation = {
        code: goodDept?.code || '',
        uid: `${dette.type}-${index}`,
        libelle: goodDept?.libelle || '',
        type: goodDept?.ordreString || '',
        amount: dette.montant,
      };

      buybackDebtsList.push(buybackDebts);
      totalDebtValue += dette.montant;
    });
    hasBuybackDebt = true;
  }
  return {
    otherNeedsList,
    buybackDebtsList,
    totalOtherNeedsAmount,
    totalDebtValue,
    hasOtherNeeds,
    hasBuybackDebt,
  };
};

const mappingIncidentsImpayes = (
  clientFile: clientFileResult,
  incidentPaymentType: CodeLibelle[],
): returnMappingIncidentsImpayes => {
  let hasPaymentIncident = false;
  let hasPaymentDefault = false;
  let incidentPaymentList: CodeLibelle[] = [];
  let nbPaymentDefault = 0;

  if (clientFile.comportementFinancier?.nombreMoisImpayesTotal > 0) {
    hasPaymentDefault = true;
    nbPaymentDefault = clientFile.comportementFinancier.nombreMoisImpayesTotal;
  }

  if (clientFile.comportementFinancier?.incidentFinanciers?.length > 0) {
    hasPaymentIncident = true;

    incidentPaymentList = incidentPaymentType.filter(incident => {
      return clientFile.comportementFinancier.incidentFinanciers.includes(incident.code);
    });
  }

  return {
    hasPaymentIncident,
    hasPaymentDefault,
    nbPaymentDefault,
    incidentPaymentList,
  };
};

const mappingOperationClient = (
  clientFile: clientFileResult,
  usageBiensList: CodeLibelle[],
  listeEtablissementPreteur: CodeLibelle[],
  additionalLoanPurpose: CodeLibelleDetail[],
  debtTypeList: CodeLibelleDetail[],
  incidentPaymentType: CodeLibelle[],
): OperationState => {
  const assetList = clientFile.garantieHypothecaire?.biensImmobiliers
    ? mappingAsset(
        clientFile.garantieHypothecaire.biensImmobiliers,
        usageBiensList,
        clientFile.contexteArrivee,
        !!clientFile.proposition,
      )
    : { assetList: [], totalAssetsValue: 0 };

  const operationClient: OperationState = {
    ...mappingLoan(
      clientFile.projet.credits,
      listeEtablissementPreteur,
      clientFile.contexteArrivee,
      !!clientFile.proposition,
    ),
    ...assetList,
    ...mappingAutresBesoin(clientFile, additionalLoanPurpose, debtTypeList),
    ...mappingIncidentsImpayes(clientFile, incidentPaymentType),
    hasInsurance: clientFile.projet.typeGarantie === 'HYP',
    isDataOk: clientFile.contexteArrivee !== 'ASRC' && !clientFile.proposition,
  };

  operationClient.isDataOk = checkIfDatasAreOk(operationClient);

  return operationClient;
};

export default mappingOperationClient;
