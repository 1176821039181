import AuthorizationService from 'api/AuthorizationService';
import {
  getAccesEspaceESignature,
  getSuiviESignature,
} from 'api/transfertDocumentService';
import StyledNextButton from 'components/buttons/nextButton/style';
import ActionValidatedIcon from 'icons/ActionPlusIcon';
import ActionSign from 'icons/ActionSign';
import ClockIcon2 from 'icons/ClockIcon2';
import CrossCircle from 'icons/CrossCircle';
import RefreshIcon from 'icons/RefreshIcon';
import { useEffect, useState } from 'react';
import { SuiviESignature } from 'types/packContractuelDtos';
import { formatDateFrHour } from 'utils/DateUtils';
import * as messages from '../messages';
import {
  StyledESIgnFollowupContainer,
  StyledESignFollowupInfoBubble,
  StyledESignFollowupInfoBubbleText,
  StyledESignFollowupStatusContainer,
  StyledESignRefreshLabel,
  StyledESignUpdateLabel,
} from '../style';

interface ESignatureFollowupProps {
  numeroDossier: number;
  borrowerId: number;
  coBorrowerId: number;
  contractNumber: number;
}

const ESignatureFollowup: React.FC<ESignatureFollowupProps> = ({
  numeroDossier,
  borrowerId,
  coBorrowerId,
  contractNumber,
}) => {
  const [suiviESignature, setSuiviESignature] = useState<SuiviESignature>();
  const [eSignatureUrl, setESignatureUrl] = useState<string>();
  const [backgroundColor, setBackgroundColor] = useState<string>('#E5F5FD');
  const [borderColor, setBorderColor] = useState<string>('#1068D0');
  const [icon, setIcon] = useState<React.ReactElement>(<ClockIcon2 />);
  const [followUpIsBeingCalled, setFollowUpIsBeingCalled] = useState<boolean>(false);

  const RED_LIST = ['ECH', 'ABA', 'NAC'];

  const callFollowUp = () => {
    setFollowUpIsBeingCalled(true);
    const userId = AuthorizationService.getUserInfo()?.uid || '';

    getSuiviESignature(userId, numeroDossier)
      .then(response => {
        if (response.Success && response.Resultat) {
          const resultat = response.Resultat;
          setSuiviESignature(resultat);

          if (RED_LIST.includes(resultat.statutAvancement)) {
            setBorderColor('#C8102E');
            setBackgroundColor('#FEF2F4');
            setIcon(<CrossCircle color="#C8102E" />);
          } else if (resultat.statutAvancement === 'FIN') {
            setBorderColor('#268038');
            setBackgroundColor('#EFF8F0');
            setIcon(<ActionValidatedIcon color="#268038" />);
          }
        }
      })
      .catch(error => {
        console.error(error);
        alert(
          "Une erreur technique s'est produite. L'accès au suivi de la e-signature n'est pas disponible.",
        );
      })
      .finally(() => {
        setFollowUpIsBeingCalled(false);
      });
  };

  useEffect(() => {
    const userId = AuthorizationService.getUserInfo()?.uid || '';

    callFollowUp();

    getAccesEspaceESignature(
      userId,
      borrowerId,
      coBorrowerId,
      numeroDossier,
      contractNumber,
    )
      .then(response => {
        if (response.Success && response.Resultat) {
          setESignatureUrl(response.Resultat.urlDocSpace);
        }
      })
      .catch(error => {
        console.error(error);
        alert(
          "Une erreur technique s'est produite. L'accès à la e-signature n'est pas disponible.",
        );
      });
  }, []);

  return suiviESignature &&
    suiviESignature.statutAvancement !== 'NAC' &&
    suiviESignature.libelleStatutAvancement !== 'Non renseigné' ? (
    <StyledESIgnFollowupContainer>
      <StyledESignFollowupStatusContainer>
        <StyledESignFollowupInfoBubble
          backgroundColor={backgroundColor}
          borderColor={borderColor}>
          {icon}
          <StyledESignFollowupInfoBubbleText>
            {suiviESignature?.libelleStatutAvancement}
          </StyledESignFollowupInfoBubbleText>
        </StyledESignFollowupInfoBubble>
        <div style={{ display: 'flex', gap: '1.6rem', alignItems: 'center' }}>
          {suiviESignature.dateStatutAvancement && (
            <StyledESignUpdateLabel>
              Dernière mise à jour :{' '}
              {formatDateFrHour(suiviESignature.dateStatutAvancement)}
            </StyledESignUpdateLabel>
          )}
          <StyledESignRefreshLabel
            style={{
              cursor: followUpIsBeingCalled ? 'not-allowed' : 'pointer',
              color: followUpIsBeingCalled ? '#6F757C' : '#268038',
            }}
            onKeyPress={() => {
              callFollowUp();
            }}
            onClick={() => {
              callFollowUp();
            }}
            role="button"
            tabIndex={0}>
            <RefreshIcon color={followUpIsBeingCalled ? '#6F757C' : '#268038'} />
            Actualiser
          </StyledESignRefreshLabel>
        </div>
      </StyledESignFollowupStatusContainer>

      {eSignatureUrl ? (
        <StyledNextButton
          color="#008859"
          onClick={() => {
            const win = window.open(eSignatureUrl, '_blank');
            win?.focus();
          }}>
          <ActionSign color="white" />
          <span style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '0.8rem' }}>
            {messages.ESIGN_ACCESS}
          </span>
        </StyledNextButton>
      ) : (
        <StyledNextButton disabled>
          <ActionSign color="white" />
          <span style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '0.8rem' }}>
            {messages.ESIGN_ACCESS}
          </span>
        </StyledNextButton>
      )}
    </StyledESIgnFollowupContainer>
  ) : (
    <></>
  );
};

export default ESignatureFollowup;
