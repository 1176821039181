import Modal from 'components/modals/Modal';
import { useState } from 'react';
import styled from 'styled-components';

const StyledModalEbiz = styled(Modal)`
  #ModalContentBody {
    display: flex;
    flex-direction: column;
  }
`;

const StyledGeneralModalContentContainer = styled.div`
  margin-left: 7.2rem;
  margin-right: 7.2rem;
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
`;

const StyledTitle = styled.span`
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
`;

export interface EbizModalProps {
  modalIsVisible: boolean;
}

const EbizModal: React.FC<EbizModalProps> = ({ modalIsVisible }) => {
  const [isVisible, setIsvisible] = useState<boolean>(modalIsVisible);

  return (
    <StyledModalEbiz
      isVisible={isVisible}
      height="58rem"
      width="72.4rem"
      onClose={() => {
        setIsvisible(false);
        sessionStorage.setItem('ebizModalShowed', 'true');
      }}
      closeOutside
      backgroundColor="#E32681">
      <StyledGeneralModalContentContainer>
        <StyledTitle>Nouvelles rubriques commerciales !</StyledTitle>
        <img
          style={{ height: '38rem', width: '67.8rem', marginBottom: '2.4rem' }}
          src={`${process.env.PUBLIC_URL}/newEbiz.png`}
          alt="logo"
        />
      </StyledGeneralModalContentContainer>
    </StyledModalEbiz>
  );
};

export default EbizModal;
