import Space from 'components/space/Space';
import { useEffect, useState } from 'react';
import { IdentityState } from 'reducers/identity/types';
import { hasCoBorrower } from 'containers/communs/utils';
import { PropositionOutDtoResp, SubscriptionResponseDto } from 'types/FaisabiliteDTO';
import { OperationState } from 'reducers/operationClient/types';
import { getReferential, getSegmentation } from 'api/referentialService';
import {
  determineCurrentProjectStep,
  determineProjectType,
  formatDisplayedFirstname,
  resumeProject,
} from 'utils/commun';
import BaseButton from 'components/designSystem/BaseButton';
import ContractPaper from 'icons/ContractPaper';
import theme from 'styles/theme';
import { useNavigate } from 'react-router';
import { clientFilesResult } from 'types/clientFileDtos';
import { formatDateFr } from 'utils/DateUtils';
import { CodeLibelle } from 'types';
import DownloadCard from 'components/downloadCard/DownloadCard';
import NavBack from 'components/navBack/NavBack';
import {
  StyledAccentContainer,
  StyledCreationDossierText,
  StyledCurrentStepLabel,
  StyledFileInfoContainer,
  StyledFileInfoLabel,
  StyledLoanDetailData,
  StyledLoanSummaryCard,
  StyledLoanSummaryCardContainer,
  StyledLoanSummaryCardData,
  StyledLoanSummaryCardLabel,
  StyledLoanSummaryContainer,
  StyledLoanSummaryMainCard,
  StyledLoanSummaryMainCardData,
  StyledLoanSummaryMainCardDetail,
  StyledLoanSummaryMainCardLabel,
  StyledMainNavBarContainer,
  StyledProjectDetailsLongContainer,
  StyledProjectDetailsShortContainer,
  StyledResumeButtonText,
  StyledSecondaryNavBarContainer,
  StyledStatusDossierContainer,
  StyledStatutText,
} from './style';
import * as messages from './messages';
import IdentiteClientConsultation from './donneesClient/identiteClient/identiteClientConsultation';
import SecondaryNavBarTile from './components/secondaryNavBarTile';
import MainNavBarTile from './components/mainNavBarTile';
import SituationFoyerConsultation from './donneesClient/situationFoyer/situationFoyerConsultation';
import SituationProfessionnelleConsultation from './donneesClient/situationProfessionnelle/situationProfessionnelleConsultation';
import RevenusEtChargesConsultation from './donneesClient/revenusEtCharges/revenusEtChargesConsultation';
import GarantieConsultation from './operationsClient/garantie/garantieConsultation';
import AutresBesoinsConsultation from './operationsClient/autresBesoins/autreBesoinsConsultation';
import ResultatsConsultation from './conditionsFinancieres/resultats/resultatsConsultation';
import IncidentsImpayesConsultation from './operationsClient/incidentsImpayes/incidentsImpayesConsultation';
import FinalisationProjetConsultation from './finalisationProjet/finalisationProjetConsultation';
import PretsConsultation from './operationsClient/prets/pretsConsultation';
import ConditionsFinancieresConsultation from './conditionsFinancieres/conditionsFinancieres/conditionsFinancieresConsultation';
import RecapTransmissionNav from './components/recapTransmissionNav';
import TransmissionPiecesConsultation from './transmissionPiecesConsultation/transmissionPiecesConsultation';
import ESignatureFollowup from './components/eSignatureFollowup';

const ConsultationForm: React.FC = () => {
  const navigate = useNavigate();

  type MainNavBarSteps =
    | { number: 1; label: 'identite' }
    | { number: 2; label: 'foyer' }
    | { number: 3; label: 'pro' }
    | { number: 4; label: 'revenus_charges' }
    | { number: 5; label: 'garantie' }
    | { number: 6; label: 'prets' }
    | { number: 7; label: 'besoins' }
    | { number: 8; label: 'incidents' }
    | { number: 9; label: 'conditions' }
    | { number: 10; label: 'resultat' }
    | { number: 11; label: 'finalisation' };

  const [navBarsStep, setnavBarsStep] = useState<MainNavBarSteps>({
    number: 1,
    label: 'identite',
  });
  const [isRecap, setIsRecap] = useState<boolean>(true);
  const [currentProjectStep] = useState<number>(determineCurrentProjectStep());

  const [projectDetails, setProjectDetails] = useState<clientFilesResult>();

  const [borrowerIdentity, setBorrowerIdentity] = useState<IdentityState>();
  const [coBorrowerIdentity, setCoBorrowerIdentity] = useState<IdentityState>();

  const [simulationResult, setSimulationResult] = useState<SubscriptionResponseDto>();
  const [operationsClient, setOperationsClient] = useState<OperationState>();
  const [clientNeed, setClientNeed] = useState<number>();

  const [segmentation, setSegmentation] = useState<string>();
  const [etudieur, setEtudieur] = useState<string>();

  const [isProjectEditable, setIsProjectEditable] = useState<boolean>(true);

  const [projectStatus, setProjectStatus] = useState<CodeLibelle>();

  const setCurrentProjectStepLabel = () => {
    const tempProjectStepLabels = new Map();
    tempProjectStepLabels.set(1, 'Identité client');
    tempProjectStepLabels.set(2, 'Situation foyer');
    tempProjectStepLabels.set(3, 'Situation professionnelle');
    tempProjectStepLabels.set(4, 'Revenus et charges');
    tempProjectStepLabels.set(5, 'Opération client');
    tempProjectStepLabels.set(6, 'Opération client');
    tempProjectStepLabels.set(7, 'Opération client');
    tempProjectStepLabels.set(8, 'Opération client');
    tempProjectStepLabels.set(9, 'Conditions financières');
    tempProjectStepLabels.set(10, 'Resultat');
    tempProjectStepLabels.set(11, 'Finalisation');
    return tempProjectStepLabels;
  };

  const [projectStepLabels] = useState<Map<number, string>>(setCurrentProjectStepLabel());

  const renderMainCardContent = () => {
    switch (navBarsStep.label) {
      case 'identite':
        return (
          <IdentiteClientConsultation
            isProjectEditable={isProjectEditable}
            borrowerState={borrowerIdentity}
            coBorrowerState={hasCoBorrower() ? coBorrowerIdentity : undefined}
          />
        );
      case 'foyer':
        return <SituationFoyerConsultation isProjectEditable={isProjectEditable} />;
      case 'pro':
        return (
          <SituationProfessionnelleConsultation isProjectEditable={isProjectEditable} />
        );
      case 'revenus_charges':
        return <RevenusEtChargesConsultation isProjectEditable={isProjectEditable} />;
      case 'garantie':
        return (
          <>
            <GarantieConsultation isProjectEditable={isProjectEditable} />
          </>
        );
      case 'prets':
        return <PretsConsultation isProjectEditable={isProjectEditable} />;
      case 'besoins':
        return <AutresBesoinsConsultation isProjectEditable={isProjectEditable} />;
      case 'incidents':
        return <IncidentsImpayesConsultation isProjectEditable={isProjectEditable} />;
      case 'conditions':
        return (
          <ConditionsFinancieresConsultation isProjectEditable={isProjectEditable} />
        );
      case 'resultat':
        return <ResultatsConsultation isProjectEditable={isProjectEditable} />;
      case 'finalisation':
        return <FinalisationProjetConsultation isProjectEditable={isProjectEditable} />;
      default:
        return <></>;
    }
  };

  const renderMainNavBar = () => {
    return (
      <StyledMainNavBarContainer>
        <MainNavBarTile
          label={messages.MAIN_NAVBAR_TAB_1}
          isSelected={navBarsStep.number < 5}
          isEnabled={currentProjectStep > 0}
          onclick={() => {
            setnavBarsStep({ number: 1, label: 'identite' });
          }}
        />
        <MainNavBarTile
          label={messages.MAIN_NAVBAR_TAB_2}
          isSelected={navBarsStep.number > 4 && navBarsStep.number < 9}
          isEnabled={currentProjectStep > 4}
          onclick={() => {
            setnavBarsStep({ number: 5, label: 'garantie' });
          }}
        />
        <MainNavBarTile
          label={messages.MAIN_NAVBAR_TAB_3}
          isSelected={['conditions', 'resultat'].includes(navBarsStep.label)}
          isEnabled={currentProjectStep > 8}
          onclick={() => {
            setnavBarsStep({ number: 9, label: 'conditions' });
          }}
        />
        <MainNavBarTile
          label={messages.MAIN_NAVBAR_TAB_4}
          isSelected={navBarsStep.label === 'finalisation'}
          isEnabled={!isProjectEditable}
          onclick={() => {
            setnavBarsStep({ number: 11, label: 'finalisation' });
          }}
        />
      </StyledMainNavBarContainer>
    );
  };

  const renderSecondaryNavBar = () => {
    switch (navBarsStep.label) {
      case 'identite':
      case 'foyer':
      case 'pro':
      case 'revenus_charges':
        return (
          <>
            <Space marginBottom="2.4rem" />
            <StyledSecondaryNavBarContainer>
              <SecondaryNavBarTile
                label={messages.SECODARY_NAVBAR_TAB_1_1}
                isSelected={navBarsStep.label === 'identite'}
                isEnabled={currentProjectStep > 0}
                onclick={() => setnavBarsStep({ number: 1, label: 'identite' })}
              />
              <SecondaryNavBarTile
                label={messages.SECODARY_NAVBAR_TAB_1_2}
                isSelected={navBarsStep.label === 'foyer'}
                isEnabled={currentProjectStep > 1}
                onclick={() => setnavBarsStep({ number: 2, label: 'foyer' })}
              />
              <SecondaryNavBarTile
                label={messages.SECODARY_NAVBAR_TAB_1_3}
                isSelected={navBarsStep.label === 'pro'}
                isEnabled={currentProjectStep > 2}
                onclick={() => setnavBarsStep({ number: 3, label: 'pro' })}
              />
              <SecondaryNavBarTile
                label={messages.SECODARY_NAVBAR_TAB_1_4}
                isSelected={navBarsStep.label === 'revenus_charges'}
                isEnabled={currentProjectStep > 3}
                onclick={() => setnavBarsStep({ number: 4, label: 'revenus_charges' })}
              />
            </StyledSecondaryNavBarContainer>
          </>
        );
      case 'garantie':
      case 'prets':
      case 'besoins':
      case 'incidents':
        return (
          <>
            <Space marginBottom="2.4rem" />
            <StyledSecondaryNavBarContainer>
              <SecondaryNavBarTile
                label={messages.SECODARY_NAVBAR_TAB_2_1}
                isSelected={navBarsStep.label === 'garantie'}
                isEnabled={currentProjectStep > 4}
                onclick={() => setnavBarsStep({ number: 5, label: 'garantie' })}
              />
              <SecondaryNavBarTile
                label={messages.SECODARY_NAVBAR_TAB_2_2}
                isSelected={navBarsStep.label === 'prets'}
                isEnabled={currentProjectStep > 5}
                onclick={() => setnavBarsStep({ number: 6, label: 'prets' })}
              />
              <SecondaryNavBarTile
                label={messages.SECODARY_NAVBAR_TAB_2_3}
                isSelected={navBarsStep.label === 'besoins'}
                isEnabled={currentProjectStep > 6}
                onclick={() => setnavBarsStep({ number: 7, label: 'besoins' })}
              />
              <SecondaryNavBarTile
                label={messages.SECODARY_NAVBAR_TAB_2_4}
                isSelected={navBarsStep.label === 'incidents'}
                isEnabled={currentProjectStep > 7}
                onclick={() => setnavBarsStep({ number: 8, label: 'incidents' })}
              />
            </StyledSecondaryNavBarContainer>
          </>
        );
      case 'conditions':
      case 'resultat':
        return (
          <>
            <Space marginBottom="2.4rem" />
            <StyledSecondaryNavBarContainer>
              <SecondaryNavBarTile
                label={messages.SECODARY_NAVBAR_TAB_3_1}
                isSelected={navBarsStep.label === 'conditions'}
                isEnabled={currentProjectStep > 8}
                onclick={() => setnavBarsStep({ number: 9, label: 'conditions' })}
              />
              <SecondaryNavBarTile
                label={messages.SECODARY_NAVBAR_TAB_3_2}
                isSelected={navBarsStep.label === 'resultat'}
                isEnabled={currentProjectStep > 9}
                onclick={() => setnavBarsStep({ number: 10, label: 'resultat' })}
              />
            </StyledSecondaryNavBarContainer>
          </>
        );
      default:
        return <></>;
    }
  };

  const setIdentityFromSession = (
    borrower: 'borrowerIdentity' | 'coBorrowerIdentity',
  ) => {
    const identityFromSession = sessionStorage.getItem(borrower);
    if (identityFromSession) {
      const identity = JSON.parse(identityFromSession) as IdentityState;

      if (borrower === 'borrowerIdentity') setBorrowerIdentity(identity);
      else setCoBorrowerIdentity(identity);
    }
  };

  const getFullName = (identity: IdentityState): string => {
    return `${identity.civility} ${identity.lastName} ${formatDisplayedFirstname(
      identity.firstName || '',
    )}`;
  };

  const getDataLabel = (data: any): string => {
    return data ? data.toString() : 'Non renseignée';
  };

  useEffect(() => {
    let objectFromSession = sessionStorage.getItem('projetDetail');

    if (objectFromSession) {
      const projetDetailFromSession = JSON.parse(objectFromSession) as clientFilesResult;
      setProjectDetails(projetDetailFromSession);

      getReferential('statutAvancement').then(resultat => {
        resultat.Resultat.forEach(codeLibelle => {
          if (codeLibelle.code === projetDetailFromSession?.StatutMaitre)
            setProjectStatus(codeLibelle);
        });
      });
      if (projetDetailFromSession?.StatutMaitre === 'E') setIsProjectEditable(true);
      else setIsProjectEditable(false);
    }
    objectFromSession = sessionStorage.getItem('simulationResult');

    if (objectFromSession) {
      const simulationResultFromSession = JSON.parse(
        objectFromSession,
      ) as PropositionOutDtoResp;

      setSimulationResult(simulationResultFromSession.Resultat);
    }

    objectFromSession = sessionStorage.getItem('operationsClient');

    if (objectFromSession) {
      const operationsClientFromSession = JSON.parse(objectFromSession) as OperationState;

      setOperationsClient(operationsClientFromSession);

      const clientNeedSum =
        (operationsClientFromSession.buybackLoansTotal || 0) +
        operationsClientFromSession.totalOtherNeedsAmount +
        operationsClientFromSession.totalDebtValue;

      setClientNeed(clientNeedSum);
    }

    setIdentityFromSession('borrowerIdentity');
    if (hasCoBorrower()) setIdentityFromSession('coBorrowerIdentity');

    setEtudieur(sessionStorage.getItem('etudieur') || undefined);
  }, []);

  useEffect(() => {
    if (simulationResult && operationsClient) {
      getSegmentation().then(result => {
        result.Resultat.forEach(segmentationType => {
          if (segmentationType.code === simulationResult?.Proposition.segmentation) {
            setSegmentation(segmentationType.libelle);
          }
        });
      });
    }
  }, [simulationResult, operationsClient]);

  const renderResumeInput = () => {
    return (
      <>
        <BaseButton
          onClick={() => {
            resumeProject(
              !simulationResult?.Proposition ? 1 : currentProjectStep,
              navigate,
            );
          }}
          style={{
            width: '35rem',
            background: theme.colors.chartColors.mainGreenBnp500,
          }}>
          <ContractPaper />
          <StyledResumeButtonText>{messages.RESUME_BUTTON_LABEL}</StyledResumeButtonText>
        </BaseButton>
        <Space marginBottom="0.8rem" />

        {projectStepLabels.get(currentProjectStep) && (
          <StyledCurrentStepLabel>{`Etape en cours de saisie : ${projectStepLabels.get(
            !simulationResult?.Proposition ? 1 : currentProjectStep,
          )}`}</StyledCurrentStepLabel>
        )}
      </>
    );
  };

  const renderNavRecapTransmission = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <RecapTransmissionNav
            label={messages.PROJECT_RECAP_MAIN_TITLE}
            isSelected={isRecap}
            isEnabled
            onclick={() => {
              setIsRecap(true);
            }}
          />
        </div>
        <div>
          <RecapTransmissionNav
            label={messages.PROJECT_RECAP_SECONDARY_TITLE}
            isSelected={!isRecap}
            isEnabled={!isProjectEditable}
            onclick={() => {
              setIsRecap(false);
            }}
          />
        </div>
      </div>
    );
  };

  const renderRecap = () => {
    return (
      <>
        <Space marginBottom="2.4rem" />

        {renderMainNavBar()}

        {renderSecondaryNavBar()}

        <Space marginBottom="2.4rem" />

        {renderMainCardContent()}

        <Space marginBottom="2.4rem" />
      </>
    );
  };

  return (
    <>
      <div style={{ maxWidth: '86.4rem', marginTop: '4.8rem' }}>
        <NavBack navigate={navigate} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '4rem',
          }}>
          <StyledStatusDossierContainer>
            <StyledAccentContainer />
            <StyledStatutText>{messages.STATUT_DOSSIER}</StyledStatutText>
            <span>{projectStatus?.libelle}</span>
          </StyledStatusDossierContainer>
          {etudieur && (
            <div style={{ display: 'flex' }}>
              <StyledStatutText>{messages.PRISE_EN_CHARGE}</StyledStatutText>{' '}
              <span>{etudieur}</span>
            </div>
          )}
        </div>

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledCreationDossierText>
            {`${messages.DATE_CREATION_LABEL}${formatDateFr(
              projectDetails?.DateCreation,
            )}`}
          </StyledCreationDossierText>
        </div>

        {!isProjectEditable && (
          <div style={{ position: 'absolute', right: '7.2rem' }}>
            <DownloadCard projectStatus={projectStatus} />
          </div>
        )}

        <StyledFileInfoContainer>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {borrowerIdentity && (
              <div>
                <StyledFileInfoLabel>{messages.FILE_INFO_BORROWER}</StyledFileInfoLabel>
                <span style={{ marginRight: '3.6rem' }}>
                  {getDataLabel(getFullName(borrowerIdentity))}
                </span>
              </div>
            )}

            {coBorrowerIdentity && (
              <div>
                <StyledFileInfoLabel>{messages.FILE_INFO_COBORROWER}</StyledFileInfoLabel>
                <span style={{ marginRight: '3.6rem' }}>
                  {getDataLabel(getFullName(coBorrowerIdentity))}
                </span>
              </div>
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'end', whiteSpace: 'nowrap' }}>
            <StyledFileInfoLabel>{messages.FILE_INFO_NUMBER}</StyledFileInfoLabel>
            <span>
              {simulationResult?.numeroProjet?.toString() ||
                sessionStorage.getItem('numeroProjet')}
            </span>
          </div>
        </StyledFileInfoContainer>

        <Space marginBottom="1.6rem" />

        {clientNeed && (
          <>
            <StyledLoanSummaryContainer>
              <StyledLoanSummaryMainCard>
                <StyledLoanSummaryCardContainer>
                  <StyledLoanSummaryMainCardLabel>
                    {messages.LOAN_SUMMARY_MAIN_LABEL}
                  </StyledLoanSummaryMainCardLabel>
                  {simulationResult && (
                    <StyledLoanSummaryMainCardData>
                      {`${simulationResult.Proposition.montantTotalPret.toLocaleString()} €`}
                    </StyledLoanSummaryMainCardData>
                  )}
                </StyledLoanSummaryCardContainer>
              </StyledLoanSummaryMainCard>

              <StyledLoanSummaryCard style={simulationResult ? { flex: '1' } : {}}>
                <StyledLoanSummaryCardContainer
                  style={simulationResult ? { width: '12rem' } : {}}>
                  <StyledLoanSummaryCardLabel>
                    {messages.LOAN_SUMMARY_NEED_LABEL}
                  </StyledLoanSummaryCardLabel>
                  <StyledLoanSummaryCardData>{`${clientNeed?.toLocaleString()} €`}</StyledLoanSummaryCardData>
                </StyledLoanSummaryCardContainer>

                {simulationResult && (
                  <>
                    <StyledLoanSummaryCardContainer>
                      <StyledLoanSummaryCardLabel>
                        {messages.LOAN_SUMMARY_PROCESSING_FEES_LABEL}
                      </StyledLoanSummaryCardLabel>
                      <StyledLoanSummaryCardData>
                        {`${simulationResult.Proposition.fraisComOuverture.toLocaleString()} €`}
                      </StyledLoanSummaryCardData>
                    </StyledLoanSummaryCardContainer>
                    <StyledLoanSummaryCardContainer>
                      <StyledLoanSummaryCardLabel>
                        {messages.LOAN_SUMMARY_FEES_LABEL}
                      </StyledLoanSummaryCardLabel>
                      <div
                        style={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
                        <StyledLoanSummaryCardData>{`${simulationResult.Proposition.fraisIntermedation} €`}</StyledLoanSummaryCardData>
                        <StyledLoanSummaryMainCardDetail>
                          {`${simulationResult.Proposition.pourcentageFraisIntermedation}%`}
                        </StyledLoanSummaryMainCardDetail>
                      </div>
                    </StyledLoanSummaryCardContainer>
                    <StyledLoanSummaryCardContainer>
                      <StyledLoanSummaryCardLabel>
                        {messages.LOAN_SUMMARY_GARANTEE_LABEL}
                      </StyledLoanSummaryCardLabel>
                      <StyledLoanSummaryCardData>{`${simulationResult.Proposition.fraisActe} €`}</StyledLoanSummaryCardData>
                    </StyledLoanSummaryCardContainer>
                  </>
                )}
              </StyledLoanSummaryCard>
            </StyledLoanSummaryContainer>
            <Space marginBottom="0.8rem" />
            <div style={{ display: 'flex', justifyContent: 'end', gap: '0.8rem' }}>
              <StyledProjectDetailsLongContainer>
                <StyledLoanSummaryCardLabel>
                  {messages.LOAN_DETAILS_TYPE}
                </StyledLoanSummaryCardLabel>
                <StyledLoanDetailData>
                  {operationsClient &&
                    determineProjectType(
                      operationsClient.hasInsurance,
                      simulationResult?.Proposition.idSecteurLegislatif.toString(),
                    )}
                </StyledLoanDetailData>
              </StyledProjectDetailsLongContainer>

              <StyledProjectDetailsShortContainer>
                <StyledLoanSummaryCardLabel>
                  {messages.LOAN_DETAILS_SEGMENTATION}
                </StyledLoanSummaryCardLabel>
                <StyledLoanDetailData>{segmentation}</StyledLoanDetailData>
              </StyledProjectDetailsShortContainer>

              <StyledProjectDetailsShortContainer>
                <StyledLoanSummaryCardLabel>
                  {messages.LOAN_DETAILS_TREATMENT}
                </StyledLoanSummaryCardLabel>
                <StyledLoanDetailData>
                  {simulationResult?.Proposition.processTraitement}
                </StyledLoanDetailData>
              </StyledProjectDetailsShortContainer>
            </div>
          </>
        )}

        <Space marginBottom="4.8rem" />

        {isProjectEditable && renderResumeInput()}
        {!isProjectEditable && (
          <ESignatureFollowup
            numeroDossier={
              simulationResult?.numeroProjet ||
              Number(sessionStorage.getItem('numeroProjet'))
            }
            borrowerId={
              simulationResult?.identifiantEmprunteur ||
              Number(sessionStorage.getItem('borrowerId'))
            }
            coBorrowerId={
              simulationResult?.identifiantCoEmprunteur ||
              Number(sessionStorage.getItem('coBorrowerId'))
            }
            contractNumber={Number(projectDetails?.NumeroContrat) || 0}
          />
        )}

        <Space marginBottom="4rem" />

        {renderNavRecapTransmission()}

        {isRecap && renderRecap()}
        {!isRecap && <TransmissionPiecesConsultation />}
      </div>
    </>
  );
};

export default ConsultationForm;
